<template>
  <div class="viewOnlineExam">
    <v-row>
      <v-col cols="12">
        <!-- <div class="title"> -->
        <!-- <h1 class="mb-2">{{ homeWorkData.application_number }}</h1> -->
        <!-- </div> -->
        <div class="title">
          <h2 class="main-color" v-if="homeWorkData.subject_name != ''">
            {{ homeWorkData.subject }} - {{ homeWorkData.title }}
          </h2>
          <h2 class="main-color" v-else>
            {{ homeWorkData.homeWork_name }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="10" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Grade") }}: </strong>{{ homeWorkData.grade }}
          </li>
          <li>
            <strong> {{ $t("Due Date") }}: </strong>{{ homeWorkData.due_date }}
          </li>
          <li>
            <strong> {{ $t("Marks") }}:</strong> {{ homeWorkData.mark }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="sectionBody"
        v-for="(section, index) in homeWorkData.sections"
        :key="index"
      >
        <p class="section-title main-color">
          {{ section.section_title }}
        </p>

        <v-row
          v-for="(question, qindex) in section['questions']"
          :key="qindex"
          class="question-div"
        >
          <v-col cols="6" sm="10">
            <p class="text-left question">
              <strong v-html="question.question" class="answer-img-container">
              </strong>
            </p>
          </v-col>
          <v-col cols="6" sm="2">
            <p class="text-right main-color">
              {{ $t("Marks") }}: {{ question.Marks }}
            </p>
          </v-col>

          <v-col cols="12" sm="12" class="pt-0">
            <p v-if="question.type == 'essay'" style="color: green">
              {{ question.student_answer }}
            </p>
            <a
              :href="question.file"
              v-if="question.type == 'essay' && question.file"
              target="_blank"
              class="router-link attach"
              >{{ $t("Answer Attachment") }}</a
            >
            <!-- mcq -->
            <v-radio-group
              v-model="question.student_answer"
              v-if="question.type == 'tof' || question.type == 'mcq'"
              row
              readonly
            >
              <v-radio
                v-for="(option, Oindex) in question.options"
                :key="Oindex"
                label=""
                :value="option.value"
                readonly
                color="green"
              >
                <template slot="label">
                  <p class="answer-img-container" v-html="option.value"></p>
                </template>
              </v-radio>
            </v-radio-group>
            <!-- join -->
            <div v-if="question.type == 'join'">
              <v-row v-for="(join, Jindex) in question.joins" :key="Jindex">
                <v-col cols="2">
                  <p
                    :hidden="
                      join.join_question == '' || join.join_question == null
                    "
                  >
                    {{ join.join_question_number }} . {{ join.join_question }}
                  </p>
                </v-col>
                <v-col cols="2">
                  <div
                    :hidden="
                      join.join_question == '' || join.join_question == null
                    "
                  >
                    <v-select
                      :items="join.all_answers_items"
                      solo
                      v-model="join.student_answer"
                      readonly
                      color="green"
                    >
                    </v-select>
                  </div>
                </v-col>
                <v-col cols="2">
                  <p>{{ join.join_answer_number }} . {{ join.join_answer }}</p>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="pa-0 grey-background"
            v-if="
              homeWorkData.allow_multi_submissions &&
              homeWorkData.can_try_answer
            "
          >
            <v-row>
              <hr class="hr" />
              <v-col cols="12" sm="6" class="py-0">
                <router-link
                  :to="`/questionsubmit/${homeWorkId}/${question.question_id}`"
                  class="router-link"
                  >{{ $t("try another answer?") }}
                </router-link>
              </v-col>
              <v-col cols="12" sm="6" class="text-right py-0">
                <span
                  class="router-link px-2 d-inline-block"
                  @click="openAnswers(question.question_id)"
                  v-if="question.answers"
                  >{{ $t("All Answers") }} ({{ question.answers.length }})</span
                >
                <span
                  class="router-link px-2 d-inline-block"
                  @click="openReplys(question.question_id)"
                  v-if="question.coments"
                >
                  <v-icon class="main-color"> speaker_notes </v-icon>
                  {{ $t("Reply") }} ({{ question.coments.length }})</span
                >
              </v-col>
              <hr class="hr" />
            </v-row>
          </v-col>

          <v-col
            cols="12"
            class="reply-sec grey-background"
            v-if="
              homeWorkData.allow_multi_submissions &&
              homeWorkData.can_try_answer
            "
          >
            <v-text-field
              class="reply-input"
              append-outer-icon="send"
              :placeholder="$t('Reply') + ' ...'"
              v-model="question.comment"
              @click:append-outer="addReply(question.question_id, question)"
              @keyup.enter="addReply(question.question_id, question)"
              filled
              rounded
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            class="pb-0 replies"
            v-if="
              showReplies &&
              question &&
              question.coments &&
              question.coments.length > 0 &&
              selectedQuestion == question.question_id
            "
          >
            <div
              class="comment d-flex mb-4"
              v-for="(comment, index) in question.coments"
              :key="index"
            >
              <div class="avater">
                <img
                  :src="comment.user_logo"
                  class="user-avater"
                  alt=""
                  width="50px"
                />
              </div>
              <div class="content">
                <p class="name main-color mb-0">{{ comment.user_name }}</p>
                <p class="date-val mb-0">{{ comment.time }}</p>
                <p class="text mt-3 mb-0">{{ comment.comment }}</p>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            class="pb-0 answers"
            v-if="
              showAnswers &&
              question &&
              question.answers &&
              question.answers.length > 0 &&
              selectedQuestion == question.question_id
            "
          >
            <v-timeline align-top dense>
              <v-timeline-item
                v-for="message in question.answers"
                :key="message.time"
                :color="message.color"
                small
              >
                <div>
                  <div class="font-weight-normal">
                    <strong>{{ message.from }}</strong> @{{ message.time }}
                  </div>
                  <div v-html="message.message"></div>
                  <a
                    :href="message.file"
                    v-if="message.file"
                    target="_blank"
                    class="router-link attach"
                    >{{ $t("Answer Attachment") }}</a
                  >
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">{{ snackText }}</span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StudentHomeWorkDetails",
  data() {
    return {
      snack: false,
      snackText: "",
      snackColor: "",
      snackTime: 3000,
      homeWorkData: {},
      homeWorkId: "",
      showAnswers: false,
      showReplies: false,
      answers: [
        {
          from: "You",
          message: `Change you answer`,
          time: "10:42am",
          color: "deep-purple lighten-1",
        },
        {
          from: "You",
          message: "Change you answer",
          time: "10:37am",
          color: "green",
        },
        {
          from: "You",
          message: "Change you answer",
          time: "9:47am",
          color: "deep-purple lighten-1",
        },
      ],
      selectedQuestion: "",
    };
  },
  methods: {
    gethomeWorkData() {
      // axios
      //   .get(this.getApiUrl + "/exams/show/" + this.homeWorkId, {
      //     headers: {
      //       Authorization: "Bearer " + localStorage.token,
      //     },
      //   })
      //   .then((response) => {
      //     this.homeWorkData = response.data.data;
      //   });

      axios
        .get(
          this.getApiUrl +
            "/homework/studentViewHisAnswered/" +
            this.homeWorkId,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.homeWorkData = response.data.data;
        });
    },

    openAnswers(selectedQuestion) {
      this.showAnswers = true;
      this.showReplies = false;
      this.selectedQuestion = selectedQuestion;
    },
    openReplys(selectedQuestion) {
      this.showReplies = true;
      this.showAnswers = false;
      this.selectedQuestion = selectedQuestion;
    },

    addReply(questionId, questionObj) {
      console.log("add reply");
      axios
        .post(
          this.getApiUrl + "/homework/addCommentToQuestion/" + questionId,
          questionObj,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          if (response.data.status.error == false) {
            // questionObj.coments.unshift({
            //   comment: questionObj.comment,
            //   id: "",
            // });
            this.gethomeWorkData();
            questionObj.comment = "";
            this.showReplies = true;
            this.showAnswers = false;
            this.selectedQuestion = questionId;
          }
        });
    },
  },
  mounted() {
    this.homeWorkId = this.$router.currentRoute.params.homeWorkId;
    this.gethomeWorkData();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.question-div {
  // border: 1px solid #ddd;
  box-shadow: rgb(0 0 0 / 35%) 0px 2px 5px;
  margin-bottom: 5px;
}
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 30%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.sectionBody {
  box-shadow: $box-shadow;
  margin-top: 1rem;
  padding: 1rem;
}
.question {
  margin-bottom: 0;
}
.section-title {
  font-size: 1.4rem;
}

hr.hr {
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
}
.router-link {
  color: #7297ff !important;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 0;
}
.attach.router-link {
  text-decoration: underline;
}

.replies {
  .comment {
    background-color: #eef3ff;
    padding: 25px 0;
    .user-avater {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin: 0 10px;
    }
  }
}
.grey-background {
  background-color: #f3f3f3;
}
</style>
